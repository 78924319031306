import axios from 'axios';

import authHeader from './auth-header'

class NotificationService {
  getUnread() {
    const auth_header = authHeader();

    return axios.get(import.meta.env.VITE_APP_API_URL + '/notification/unread', {headers: auth_header});
  }

  markAsRead (id:any) {
    const auth_header = authHeader();

    return axios.post(import.meta.env.VITE_APP_API_URL + '/notification/mark-as-read', { id: id }, {headers: auth_header});
  }
}
export default new NotificationService();
