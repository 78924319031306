// Pinia Store
import { defineStore } from 'pinia'

import io from "socket.io-client";

interface State {
  socket: Object | null
}

export const useSocketStore = defineStore('socket', {
  state: (): State => ({
    socket: io(`${import.meta.env.VITE_APP_API_URL}`, { transports: ['websocket'] }),
  }),

  actions: {
    // MUTATIONS

    // ACTIONS
  },

  getters: { },
});
