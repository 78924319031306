<template>
  <VaDropdown :offset="[13, 0]" class="notification-dropdown" stick-to-edges :close-on-content-click="false">
    <template #anchor>
      <VaButton preset="secondary" color="textPrimary">
        <VaBadge overlap>
          <template #text>{{ this.notifications.filter((item) => { return !item.read }).length }}</template>
          <VaIconNotification class="notification-dropdown__icon" />
        </VaBadge>
      </VaButton>
    </template>

    <VaDropdownContent class="h-full sm:max-w-[420px] sm:h-auto">
      <section class="sm:max-h-[320px] p-4 overflow-auto">
        <VaList class="space-y-1 mb-2">
          <template v-for="(item, index) in notificationsWithRelativeTime" :key="item.id">

            <VaListItem class="text-base notification-list-item" :style="`--notification-hover-color: ${ item.type == 'ERROR' ? 'var(--va-danger)' : 'var(--va-primary)' };`">
              <VaListItemSection icon class="mx-0 p-0">
                <VaIcon :name="item.icon" :color="item.type == 'ERROR' ? 'danger' : 'primary'" />
              </VaListItemSection>

              <VaListItemSection :style="`color: ${ item.type == 'ERROR' ? 'var(--va-danger)' : 'var(--va-primary)' };`">
                {{ item.message }}
              </VaListItemSection>

              <VaListItemSection icon class="mx-1">
                {{ getNotificationRelativeTime( item ) }}
              </VaListItemSection>

              <VaListItemSection icon class="mx-0 p-0" v-if="!item.read">
                <va-button @click="markAsRead(item)" preset="plain" icon="eye"></va-button>
              </VaListItemSection>
            </VaListItem>

            <VaListSeparator v-if="item.separator && index !== notificationsWithRelativeTime.length - 1" class="mx-3" />
          </template>
        </VaList>

        <VaButton preset="primary" class="w-full" @click="displayAllNotifications = !displayAllNotifications"
          >{{ displayAllNotifications ? t('notifications.less') : t('notifications.all') }}
        </VaButton>
      </section>
    </VaDropdownContent>
  </VaDropdown>
</template>

<script>
import { defineComponent } from 'vue'

import { useI18n } from 'vue-i18n'

import VaIconNotification from '../../../icons/VaIconNotification.vue'

import notificationService from '@/services/notification.service';

import { useAuthStore } from '@/stores/auth.store'
import { useSocketStore } from '@/stores/socket.store';

export default defineComponent({
  components: { VaIconNotification },

  data () {
    const socketStore = useSocketStore();
    const authStore = useAuthStore();

    const { t, locale } = useI18n()

    const rtf = new Intl.RelativeTimeFormat(locale.value, { style: 'short' })

    const TIME_NAMES = {
      second: 1000,
      minute: 1000 * 60,
      hour: 1000 * 60 * 60,
      day: 1000 * 60 * 60 * 24,
      week: 1000 * 60 * 60 * 24 * 7,
      month: 1000 * 60 * 60 * 24 * 30,
      year: 1000 * 60 * 60 * 24 * 365,
    }

    return {
      TIME_NAMES,
      t, locale,

      baseNumberOfVisibleNotifications: 4,
      rtf,
      displayAllNotifications: false,

      notifications: [ ],

      socketStore,
      authStore
    }
  },

  props: { },

  emits: [ ],

  watch: { },

  computed: {  
    notificationsWithRelativeTime () {
      const list = this.displayAllNotifications ? this.notifications : this.notifications.slice(0, this.baseNumberOfVisibleNotifications);

      return list.map((item, index) => {
        const timeDifference = Math.round(new Date().getTime() - new Date(item.updateTimestamp).getTime())
        const timeName = this.getTimeName(timeDifference)

        let separator = false

        const nextItem = list[index + 1]
        if (nextItem) {
          const nextItemDifference = Math.round(new Date().getTime() - new Date(nextItem.updateTimestamp).getTime())
          const nextItemTimeName = this.getTimeName(nextItemDifference)

          if (timeName !== nextItemTimeName) {
            separator = true
          }
        }

        return {
          ...item,
          updateTimestamp: this.rtf.format(-1 * Math.round(timeDifference / this.TIME_NAMES[timeName]), timeName),
          separator,
        }
      })
    }
  },

  methods: {
    makeDateFromNow (timeFromNow) {
      const date = new Date()
      date.setTime(date.getTime() + timeFromNow)
      return date
    },

    getTimeName (differenceTime) {
      return Object.keys(this.TIME_NAMES).reduce(
        (acc, key) => (this.TIME_NAMES[key] < differenceTime ? key : acc),
        'month',
      )
    },

    getNotificationRelativeTime ( notification ) {
      const timeDifference = Math.round(new Date().getTime() - new Date( notification.createdAt ).getTime())
      const timeName = this.getTimeName(timeDifference)

      return this.rtf.format(-1 * Math.round(timeDifference / this.TIME_NAMES[timeName]), timeName);
    },

    markAsRead ( notification ) {
      notificationService.markAsRead( notification?._id )
        .then((res) => {
          this.init();
        })
        .catch((error) => {
          console.error(error);
          this.$vaToast.init({ message: 'Error while saving!', color: 'danger' });
        });
    },

    getNotifications () {
      notificationService.getUnread()
        .then((res) => {
          let notifications = res.data;

          let i = 0;
          let unread = notifications.unread.map((notification) => {
            i += 1;

            return  {
              message: notification.subject,
              icon: notification.type == 'ERROR' ? 'warning' : 'info',
              id: i,
              separator: false,
              updateTimestamp: notification.createdAt,
              createdAt: notification.createdAt,
              read: notification['read'],
              type: notification.type,
              _id: notification._id,
            }
          });
          unread.sort((a, b) => new Date(b.updateTimestamp).getTime() - new Date(a.updateTimestamp).getTime())

          let same_day = notifications.same_day.map((notification) => {
            i += 1;

            return  {
              message: notification.subject,
              icon: notification.type == 'ERROR' ? 'warning' : 'info',
              id: i,
              separator: false,
              updateTimestamp: notification.createdAt,
              createdAt: notification.createdAt,
              read: notification['read'],
              type: notification.type,
              _id: notification._id,
            }
          });
          same_day.sort((a, b) => new Date(b.updateTimestamp).getTime() - new Date(a.updateTimestamp).getTime())

          this.notifications = [];
          this.notifications = this.notifications.concat( unread )

          if( this.notifications.length > 0 ) {
            this.notifications[ this.notifications.length - 1 ]['separator'] = true;
          }

          this.notifications = this.notifications.concat( same_day )
        }).catch((error) => {
          console.error(error);
          this.$vaToast.init({ message: 'Error while loading the page!', color: 'danger' });
        });
    },

    play_sound () {
      let audio = new Audio('/sounds/notification_sound.wav');
      audio.play();
    },

    init () {
      this.getNotifications();

      this.socketStore.socket.emit('register', { id: this.authStore.user._id})

      this.socketStore.socket.on('notification', async (...args) => {
        this.getNotifications();

        this.play_sound();

        this.$vaToast.init({ message: '[SYSTEM] - Neue Nachricht!', color: 'info' });
      });
    }
  },

  async mounted () {
    this.init();
  }
})
</script>

<style lang="scss">
.notification-dropdown {
  cursor: pointer;

  .notification-dropdown__icon {
    position: relative;
    display: flex;
    align-items: center;
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}

.notification-list-item {
  cursor: pointer;
  position: relative;

  padding: 4px;

  &::after {
    position: absolute;
    content: "";
    background: var(--notification-hover-color);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    border-radius: 4px;
  }

  &:hover::after {    
    opacity: 0.4
  }
}
</style>
